import React, { useState, useEffect } from 'react';
import ModalWindow from '../components/ModalWindow';
import FormHeader from '../components/FormHeader';
import FormVarH from '../components/FormVarH';
import SubmitButton from '../components/SubmitButton';
import SearchBarCP from '../components/SearchBarCP';
import LoadingSpinner from '../components/LoadingSpinner';
import SubMenu from '../components/SubMenu';
import DateFormat from '../components/DateFormat';
import ModalNotesCp from '../components/ModalNotesCp';
import { toast } from 'react-toastify';
import FileLocation from '../components/FileLocation';
import { useUserData } from '../context/UserData';
import Modal from '../components/Modal';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ServiceCallList = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/service_crud.php`;
  const userData = useUserData();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedServiceCall, setSelectedServiceCall] = useState(null);
  const [serviceCallForm, setServiceCallForm] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [filterItems, setFilterItems] = useState([]);
  const [fetchItems, setFetchItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const fetchServiceCalls = async () => {
      try {
        const response = await axios.get(`${apiUrl}/service_crud.php?getList=true`, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        });
        const serviceCalls = Array.isArray(response.data) ? response.data : [];
        if (serviceCalls.length === 0) {
          toast.error("No service calls found!", { position: "top-center" });
        }
        setFilterItems(serviceCalls);
        setFetchItems(serviceCalls);
      } catch (error) {
        toast.error("Oops minor issue with the data.", { position: "top-center" });
        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", { position: "top-center" });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", { position: "top-center" });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchServiceCalls();
  }, [apiUrl, navigate]);

  const handleNewServiceCall = () => {
    setIsEditMode(false);
    setServiceCallForm({});
    setIsModalOpen(true);
  };

  const handleEditServiceCall = (serviceCall) => {
    setIsEditMode(true);
    setServiceCallForm(serviceCall);
    setIsModalOpen(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setServiceCallForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmitServiceCall = async (e) => {
    e.preventDefault();
    const endpoint = isEditMode ? `${api}?updateServiceCall=true` : `${api}?createServiceCall=true`;

    try {
      const response = await axios.post(endpoint, serviceCallForm, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.data.success) {
        toast.success('Service Call saved successfully!', { position: 'top-center' });
        setIsModalOpen(false);
        setServiceCallForm({});
        // Reload the service calls
        setFilterItems((prev) => {
          if (isEditMode) {
            return prev.map((item) =>
              item.id === serviceCallForm.id ? response.data.serviceCall : item
            );
          } else {
            return [response.data.serviceCall, ...prev];
          }
        });
      } else {
        toast.error('Failed to save the service call.', { position: 'top-center' });
      }
    } catch (error) {
      toast.error('Error saving the service call.', { position: 'top-center' });
    }
  };

  const handleDeleteServiceCall = async (id) => {
    try {
      const response = await axios.post(`${api}?deleteServiceCall=true`, { id }, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.data.success) {
        toast.success('Service Call deleted successfully!', { position: 'top-center' });
        setFilterItems((prev) => prev.filter((item) => item.id !== id));
      } else {
        toast.error('Failed to delete the service call.', { position: 'top-center' });
      }
    } catch (error) {
      toast.error('Error deleting the service call.', { position: 'top-center' });
    }
  };

  const getLinksForJob = (object) => {
    return [
      { name: 'Edit Call', click: () => handleEditServiceCall(object), key: `edit-${object.id}` },
      { name: 'Delete Call', click: () => handleDeleteServiceCall(object.id), key: `delete-${object.id}` },
    ];
  };

  return (
    <div className="mx-auto max-w-6xl sm:px-6 lg:px-8 py-10">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900 py-4">Service Dashboard</h1>
        </div>
      </div>

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="sm:flex sm:items-center py-6">
            <div className="flex w-full items-center mt-5 sm:mt-0">
              <button
                type="button"
                onClick={handleNewServiceCall}
                className="mr-2 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-600"
              >
                + New Service Call
              </button>

              <div className="flex-grow">
                <SearchBarCP search={searchTerm} handleChange={handleInputChange} onClick={() => {}} />
              </div>
            </div>
          </div>

          <div className="inline-block min-w-full py-2 align-middle">
            {loading ? (
              <LoadingSpinner>Loading...</LoadingSpinner>
            ) : (
              <div className="shadow ring-1 ring-black ring-opacity-5">
                <table className="min-w-full border-separate border-spacing-0">
                  <thead className="top-0 z-30 bg-white">
                    <tr>
                      <th className="top-0 z-30 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
                        MenuId
                      </th>
                      <th className="top-0 z-30 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
                        User|Time-Stamp
                      </th>
                      <th className="top-0 z-30 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                        Customer
                      </th>
                      <th className="top-0 z-30 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell">
                        Condition
                      </th>
                      <th className="top-0 z-30 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                        Called By
                      </th>
                      <th className="top-0 z-30 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8">
                        Tech
                      </th>
                      <th className="top-0 z-30 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8">
                        File
                      </th>
                      <th className="top-0 z-30 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8">
                        Notes
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterItems.map((object, objectIdx) => (
                      <tr key={object.id} className={`${objectIdx % 2 === 0 ? 'bg-white' : 'bg-gray-100'} divide-x`}>
                        
                        <td className={classNames(
                          objectIdx !== filterItems.length - 1 ? 'border-b border-gray-200' : '',
                          'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8')}>
                          <SubMenu label={object.id} idItem={object.id} links={getLinksForJob(object)} />
                        </td>

                        <td className={classNames(
                          objectIdx !== filterItems.length - 1 ? 'border-b border-gray-200' : '',
                          'py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8')}>
                          <div className="flex flex-col">
                            <span className="text-gray-800 text-xs">
                              <DateFormat date={object.DATE} /> | {object.TIME_LOG}
                            </span>
                            <span className="text-gray-400">
                              {object.USER}
                            </span>
                          </div>
                        </td>

                        <td className="px-4">
                          <div className="flex flex-col">
                            <span className='text-gray-800 whitespace-nowrap text-sm'>
                              {object.ADDRESS}
                            </span>
                            <span className='text-gray-600 text-xs'>
                              {object.CUSTOMER}
                            </span>
                          </div>
                        </td>
  
                        <td className="px-4">
                          <span className='text-gray-800 text-xs'>{object.TROUBLE}</span>
                        </td>

                        <td className="px-4">
                          <div className="flex flex-col">
                            <span className='text-gray-800 whitespace-nowrap text-sm'>
                              {object.CALLED_BY}
                            </span>
                            <span className='text-gray-600 text-xs'>
                              {object.PHONE}
                            </span>
                          </div>
                        </td>

                        <td className="px-4">
                          <span className='text-gray-800'>
                            {object.TECH}
                          </span>
                        </td>

                        <td className="px-4">
                          <FileLocation expFile={object.SERVICE_TICKET} where={'delivery_receipt'} />
                        </td>

                        <td className="px-2">
                          <span className='text-gray-800 text-xs'>
                            {object.NOTES}
                          </span>
                        </td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className="text-lg font-semibold text-gray-900">{isEditMode ? 'Edit Service Call' : 'New Service Call'}</h2>
        <form onSubmit={handleSubmitServiceCall}>
          <div className="space-y-4">
            <FormVarH label="Customer" inside="CUSTOMER" val={serviceCallForm.CUSTOMER || ''} onChange={handleInputChange} required />
            <FormVarH label="Address" inside="ADDRESS" val={serviceCallForm.ADDRESS || ''} onChange={handleInputChange} required />
            <FormVarH label="Trouble" inside="TROUBLE" val={serviceCallForm.TROUBLE || ''} onChange={handleInputChange} required />
            <FormVarH label="Called By" inside="CALLED_BY" val={serviceCallForm.CALLED_BY || ''} onChange={handleInputChange} required />
            <FormVarH label="Phone" inside="PHONE" val={serviceCallForm.PHONE || ''} onChange={handleInputChange} required />
            <FormVarH label="Tech" inside="TECH" val={serviceCallForm.TECH || ''} onChange={handleInputChange} required />
            <FormVarH label="Notes" inside="NOTES" val={serviceCallForm.NOTES || ''} onChange={handleInputChange} />
          </div>
          <div className="mt-6 flex justify-end">
            {/* <button
              type="button"
              className="mr-3 rounded-md bg-white px-4 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </button> */}
            <SubmitButton>{isEditMode ? 'Update' : 'Create'}</SubmitButton>
          </div>
        </form>
      </Modal>
      
    </div>
  );
};

export default ServiceCallList;
