import React from 'react'
import SideNav from '../components/SideNav';
import PostComment from '../components/PostComment';
import Comment from '../components/Comment';
import { MinusIcon } from '@heroicons/react/24/outline';
import DayOff from '../components/DayOff';
import Alerts from '../components/Alerts';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageLocationCp from '../components/ImageLocationCp';
// import { Nav } from 'react-bootstrap';

const HomePage = () => {
  return (
    <>
    

    {/* <div className="max-w-[1220px] mx-auto grid grid-cols-1 md:grid-cols-[200px_minmax(540px,_1fr)_260px] gap-x-4"> */}
    <div className="max-w-[1200px] mx-auto grid grid-cols-1 md:grid-cols-[220px_minmax(500px,_1fr)_270px] gap-x-6">

      {/* <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8"></div> */}
      {/* <!-- Side Nav: 200px, hidden on small screens --> */}
      <div className="hidden md:block">
        <div className='pt-6 pr-3'>
          {/* <SideNav /> */}
        </div>
      </div>
      
      {/* <!-- Main Content: Expand on small screens --> */}
      <div className=" p-4">
        {/* <PostComment /> */}

          {/* <div className="relative mb-6">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="bg-white px-2 text-gray-500">
              <MinusIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
            </span>
          </div>
        </div>
        
        <div className='..'>
          <Comment/>
        </div> */}

      </div>
      
      {/* <!-- Widgets: 200px, hidden on small screens --> */}
      <div className="hidden md:block ">
        <div className='pt-4'>
          <div className='border-2'>
            {/* <DayOff /> */}
          </div> 
        <br/>
          {/* <Alerts /> */}
        </div>
      </div>

    </div>
    <br/>

    </>
  )
} 

export default HomePage