import React, { useState, useEffect } from 'react';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid'
import CalendarViewCp from '../components/CalendarViewCp'
import CalProfileCp from '../components/CalProfileCp'
import AvatarLocation from '../components/AvatarLocation'
import ImageLocationCp from '../components/ImageLocationCp'
import { useUserData } from '../context/UserData'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TopTabs from '../components/TopTabs';
import CustomTabs from '../components/CustomTabs';
import EmployeePage from './EmployeePage';
import EmployeeDetailCp from '../components/EmployeeDetailCp';


// const stats = [
//   { label: 'Vacation days left', value: 12 },
//   { label: 'Sick days left', value: 4 },
//   { label: 'Personal days leftd', value: 3 },
// ]

const ProfilePage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [employeeData, setEmployeeData] = useState([]);
  const key = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState([]);
  const [sickStats, setSickStats] = useState([]);
  const [activeLink, setActiveLink] = useState('Calendar View');

  const handleCalendarView = () => {
    setActiveLink('Calendar View')
  };

  const handleDetail = () => {
    setActiveLink('Detail')
  };

  const handleAllStaff = () => {
    setActiveLink('All Staff')
    navigate('/employee_list')
  }

  const tabs = [
    { label: 'Calendar View', onClick: handleCalendarView },
    { label: 'Detail View', onClick: handleDetail },
    { label: 'All Staff', onClick: handleAllStaff },

  ];

  const fetchDates = async (key) => {
    try {
      const response = await axios.get(`${apiUrl}/time_attendance_crud.php?employeeProfile=${key?.key}`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }        
      });
      
      if (response.data.error === 'Authentication required') {
        navigate('/Login');
      } else {
        if (!response.data.employee) {
          toast.error("No employee name found!", {
            position: "top-center"
          });
        }
        setEmployeeData(response.data.employee);
        const stats_list = Array.isArray(response.data.stats) ? response.data.stats : [];
        setStats(stats_list);
        const sick_stats_list = Array.isArray(response.data.sick_stats) ? response.data.sick_stats : [];
        setSickStats(sick_stats_list);
        
      }
    } catch (error) {
      toast.error("Oops minor issue with the data.", {
      position: "top-center"
      });
      
      if (error.response && error.response.status === 401) {
      toast.error("Session expired. Please login again.", {
      position: "top-center"
      });
      navigate('/login');
      } else if (error.response && error.response.status === 403) {
      // setAccess(true);
      toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
      position: "top-center"
      });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDates(key);
  }, [key]);


 return (
    <div>
      <div>
        
        <ImageLocationCp className="h-32 w-full object-cover lg:h-48" 
                        image={'default_banner.png'} 
                        where={'settings_banner'}
                        notFound={'default_banner.png'}/>
      </div>
      <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
        <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
          <div className="flex">
            <AvatarLocation className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32" avatar={employeeData?.avatar} />
          </div>
          <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
            <div className="mt-6 min-w-0 flex-1 sm:hidden md:block">
              <h1 className="truncate text-2xl font-bold text-gray-900">
                {employeeData?.first_name} {employeeData?.last_name} 
              </h1>
            </div>
            <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0">
              <div className="grid grid-cols-1 divide-y divide-gray-200 border-t border-gray-200 bg-gray-50 sm:grid-cols-3 sm:divide-x sm:divide-y-0">
                {stats.map((stat) => (
                  <div key={stat.label} className="px-6 py-4 text-center text-sm font-medium">
                    <span className="text-gray-900">{Math.round(stat.value * 2)/2}</span> <span className="text-gray-600">{stat.label}</span>
                  </div>
                ))}
                {sickStats.map((stat) => (
                  <div key={stat.label} className="px-6 py-4 text-center text-sm font-medium">
                    <span className="text-gray-900">{Math.round(stat.value * 2)/2}</span>
                    <span className="text-gray-600">{stat.label}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 hidden min-w-0 flex-1 sm:block md:hidden">
          {employeeData?.first_name} {employeeData?.last_name} 
        </div>
        <br/>
        <CustomTabs tabs={tabs}/>
        <br/>
        {activeLink === 'Calendar View' && <CalendarViewCp employee={employeeData || []}/>}
        {activeLink === 'Detail' && <EmployeeDetailCp employee={employeeData || []}/>}
      </div>
    </div>

  )
 }
export default ProfilePage