import React, { useState, useEffect } from 'react';
import SideNav from '../components/SideNav';
import EmployeeList from '../components/EmployeeList';
import CustomTabs from '../components/CustomTabs';
import CertificationsListCp from '../components/CertificationsListCp';
import FleetCp from '../components/FleetCp';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Users from '../components/Users';
import { useNavigate, NavLink } from 'react-router-dom';


const EmployeeListPage = () => {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [employees, setEmployees] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeLink, setActiveLink] = useState('employeeView');
  const [employeeCerts, setEmployeeCerts] = useState([]);
  const [fleet, setFleet] = useState([]);
  const [access, setAccess] = useState(false);

  useEffect(() => {
    if (activeLink === 'employeeView') {
      fetchEmployees();
    } else if (activeLink === 'cert') {
      fetchCertifications();
    } else if (activeLink === 'fleet') {
      fetchFleet();
    }
  }, [activeLink]);

  const fetchEmployees = async () => {
    try {
      const response = await axios.get(`${apiUrl}/employee_crud.php?getList=1`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      });
      const data = response.data;
      if (data) {
        setEmployees(Array.isArray(data) ? data : []);
      } else {
        toast.error("Error fetching employees", {
          position: "top-center"
        });
      }
    } catch (error) {
      handleError(error);
    }
  };

  const fetchCertifications = async () => {
    try {
      const response = await axios.get(`${apiUrl}/employee_crud.php?getCerts=true`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      });
      const data = response.data;
      if (data) {
        setEmployeeCerts(data.certs || []);
        setAccess(data.access || []);
      } else {
        toast.error("Error fetching certifications", {
          position: "top-center"
        });
      }
    } catch (error) {
      handleError(error);
    }
  };

  const fetchFleet = async () => {
    try {
      const response = await axios.get(`${apiUrl}/employee_crud.php?getFleet=true`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      });
      const data = response.data;
      if (data) {
        setFleet(data.fleets || []);
        setAccess(data.access || []);
      } else {
        toast.error("Error fetching fleet", {
          position: "top-center"
        });
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    toast.error("Oops, minor issue with the data.", {
      position: "top-center"
    });
    if (error.response && error.response.status === 401) {
      toast.error("Session expired. Please login again.", {
        position: "top-center"
      });
      navigate('/login');
    } else if (error.response && error.response.status === 403) {
      toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
        position: "top-center"
      });
    }
  };

  const handleEditCert = async (editedCert) => {
    // Rest of your handleEditCert function
  };

  const handleAddCert = async (newCert) => {
    // Rest of your handleAddCert function
  };

  const handleDelete = async (id) => {
    // Rest of your handleDelete function
  };

  const handleFormSubmit = async (file, certId) => {
    // Rest of your handleFormSubmit function
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const tabs = [
    { label: 'Employees', onClick: () => setActiveLink('employeeView') },
    { label: 'Certifications', onClick: () => setActiveLink('cert') },
    { label: 'Fleet', onClick: () => setActiveLink('fleet') },
    { label: 'Users', onClick: () => setActiveLink('users') }
  ];

  return (
    <div className="max-w-[1200px] mx-auto grid grid-cols-1 md:grid-cols-[200px_minmax(540px,_1fr)] gap-x-4">
      <div className="hidden md:block">
        <div className='pt-6 pr-3'>
          <SideNav />
        </div>
      </div>

      <div className="p-4">
        <div className="relative mb-6">
          <h1>All Staff</h1>
          <br />
          <div className="mt-5 sm:mt-0 sm flex space-x-3 items-center mb-10">
            <NavLink to="/employee_edit/new">
              <button type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-400 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                New Employee
              </button>
            </NavLink>

            <div className="flex-grow relative rounded-md shadow-sm">
              <input
                type="text"
                name="search"
                id="search"
                value={searchTerm}
                onChange={handleSearchChange}
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                placeholder="Filter "
              />
            </div>
          </div>

          <CustomTabs tabs={tabs} />
          <div className="w-full border-t border-gray-300" />
        </div>

        <div>
          {activeLink === 'employeeView' && 
            <EmployeeList searchTerm={searchTerm || ''} 
              employees={employees} />
          }
          {activeLink === 'cert' &&
            <CertificationsListCp
              certs={employeeCerts}
              searchTerm={searchTerm}
              onEditCert={handleEditCert}
              onAddCert={handleAddCert}
              access={access}
              onFileUpload={handleFormSubmit}
              onDeleteCert={handleDelete}
            />
          }
          {activeLink === 'fleet' &&
            <FleetCp
              fleets={fleet}
              searchTerm={searchTerm}
              onEditCert={handleEditCert}
              onAddCert={handleAddCert}
              access={access}
            />
          }
          {activeLink === 'users' &&
            <Users
              fleets={fleet}
              searchTerm={searchTerm}
              onEditCert={handleEditCert}
              onAddCert={handleAddCert}
              access={access}
              onFileUpload={handleFormSubmit}
              onDeleteCert={handleDelete}
            />
          }
        </div>
      </div>
    </div>
  );
};

export default EmployeeListPage;
