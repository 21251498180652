import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AvatarLocation from '../components/AvatarLocation';
import SubmitButton from '../components/SubmitButton';
import useSubmitPost from '../hooks/handleSubmitPost';
import PhoneNumber from '../components/PhoneNumber';
import DateFormat from '../components/DateFormat';
import { dateToUnixTimestamp, addDays } from '../utils/dateToUnixTimestamp';
import { getTodaysDateUtil } from '../utils/DateFormatTodayUtil';
import { UtilgetDateTomorrow } from '../utils/UtilgetDateTomorrow';
import { UtilDateToDayConvert } from '../utils/UtilDateToDayConvert';
import BadgeFieldOffice from '../components/BadgeFieldOffice';
import VacationRequestViewCp from '../components/VacationRequestViewCp';
import { UtilConvertDateTodayDate } from '../utils/UtilConvertDateTodayDate';
import { UtilConvertTimeStampToDate } from '../utils/UtilConvertTimeStampToDate';
import LoadingSpinner from '../components/LoadingSpinner';
import { useUserData } from '../context/UserData';

const AttendancePage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/attendance_crud.php`;
  const key = useParams();
  const navigate = useNavigate();
  const [employees, setEmployees] = useState([]);
  const [access, setAccess] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const [isHoliday, setIsholiday] = useState(false);
  const [loading, setLoading] = useState(false);
  const [payPeriod, setPayPeriod] = useState(false);
  const [half_pay, setHalfPay] = useState(0);
  const userData = useUserData();
  const [week, setWeek] = useState(dateToUnixTimestamp(getTodaysDateUtil()));
  const [bodyData, setBodyData] = useState({
    status: 'submit_days',
    current_date: '',
    data: {}
  });

  const formatTimestamp = (timestamp) => {
    if (timestamp < 10000000000) {
      timestamp *= 1000;
    }
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = String(hours).padStart(2, '0');
    return `${month}-${day}-${year} @ ${hours}:${minutes} ${ampm}`;
  };

  const handleChange = (event, empId, dayId) => {
    const { name, value } = event.target;

    setEmployees(prevEmployees =>
      prevEmployees.map(emp => {
        if (emp.id === empId) {
          const updatedDays = emp.days.map(day => {
            if (day.id === dayId) {
              if (name.startsWith('date_request-')) {
                return { ...day, date_request: value };
              } else if (name.startsWith('notes-')) {
                return { ...day, notes: value };
              } else if (name.startsWith('partial_hours-')) {
                return { ...day, partial_hours: value };
              }
            }
            return day;
          });
          return { ...emp, days: updatedDays };
        }
        return emp;
      })
    );

    setBodyData(prevBodyData => {
      const newBodyData = { ...prevBodyData };
      if (!newBodyData.data[empId]) {
        newBodyData.data[empId] = {};
      }
      if (!newBodyData.data[empId][dayId]) {
        newBodyData.data[empId][dayId] = {};
      }
      newBodyData.data[empId][dayId][name.split('-')[0]] = value;
      return newBodyData;
    });
  };

  const handleVacationPicked = (event, empId, dayId) => {
    const { name, value } = event.target;

    setEmployees(prevEmployees =>
      prevEmployees.map(emp => {
        if (emp.id === empId) {
          const updatedDays = emp.days.map(day => {
            if (day.id === dayId) {
                return { ...day, date_request: '' };
            }
            return day;
          });
          return { ...emp, days: updatedDays };
        }
        return emp;
      })
    );
  };

  const handleSubmit = async (event, { bodyData, api, errorLabel, redirectPath }) => {
    event.preventDefault();

    try {
      const response = await axios.post(api, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      
      toast.info('Saved!', {
        position: 'top-center'
      });
      setBodyData({
        status: 'submit_days',
        current_date: '',
        data: {}
      });
      console.log(bodyData, api);

    } catch (error) {
      const errorMsg = `Error submitting ${errorLabel}`;
      if (error.response) {
        //console.error(`${errorMsg}`, error.response.data);
      } else if (error.request) {
        //console.error(`${errorMsg}`, error.request);
      } else {
        //console.error('Error', error.message);
      }
    }
  };

  const fetchAttendanceData = async (week) => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/attendance_crud.php?attendance=${week}`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      });
      //console.log(response.data);
      //console.log(userData);
      
      if (response.data.error === 'Authentication required') {
        navigate('/Login');
      } else {
        const employee_list = Array.isArray(response.data.employees) ? response.data.employees : [];
        if (employee_list.length === 0) {
          toast.error("No days found!", {
            position: "top-center"
          });
        }
        setBodyData(prev => ({ ...prev, current_date: week }));
        setEmployees(employee_list);
        setIsholiday(dateToUnixTimestamp(response.data.holiday) === week ? true : false);
        setPayPeriod(response.data.pay_period);
      }
    } catch (error) {
      setAccess(true);
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center"
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! It looks like you don't have access to this area, please speak to the CFO.", {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const ylasttwo = (dateString) => {
    const year = new Date(dateString).getFullYear().toString();
    const lastTwoDigits = year.slice(-2);
    return lastTwoDigits;
  };

  useEffect(() => {
    //console.log(userData);
    
    fetchAttendanceData(week);
  }, [week, apiUrl, navigate]);

  const handleWeek = (e, date) => {
    e.preventDefault();
    const timeStampDate = dateToUnixTimestamp(date);
    setWeek(timeStampDate);
  };

  const handleApprovedVacation = async (employee_id, vacation_id) => {
    const vcationBodyData = {
      employee_id: employee_id,
      vacation_request_id: vacation_id,
      status: "vacation_approval"
    }
    try {
      const response = await axios.post(api, vcationBodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if(response.data[0] === 'success')
      {
          toast.info('Saved!', {
            position: 'top-center'
          });      
        
          setEmployees(prev => prev.map(item => {
          if (item.id === parseInt(employee_id)) {
            const vcation_req = { ...item, vacation_request: '' };
            return vcation_req;
          } else {
            return item;
          }
        }));
      }
      else if(response.data[0] === 'reload'){
        window.location.reload();
      }

    } catch (error) {
      toast.error("Oops minor issue with the data.", {
        position: "top-center"
      });

      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center"
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: "top-center"
        });
      }
    }
    finally {
      setLoading(false)
    }
  }

  const handleDenyVacation = async (employee_id, vacation_id) => {
    const vcationBodyData = {
      employee_id: employee_id,
      vacation_request_id: vacation_id,
      status: "vacation_denied"
    }
    try {
      const response = await axios.post(api, vcationBodyData, {
        headers: { 'Content-Type': 'application/json' }
      });

      toast.info('Saved!', {
        position: 'top-center'
      });

      setEmployees(prev => prev.map(item => {
        if (item.id === parseInt(employee_id)) {
          const vcation_req = { ...item, vacation_request: '' };
          return vcation_req;
        } else {
          return item;
        }
      }));
    } catch (error) {
      toast.error("Oops minor issue with the data.", {
        position: "top-center"
      });

      if (error.response && error.response.status === 401) {
               toast.error("Session expired. Please login again.", {
          position: "top-center"
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: "top-center"
        });
      }
    }
    finally {
      setLoading(false)
    }
  }

  const setWeekTo7DaysFromToday = () => {
    const newWeek = dateToUnixTimestamp(addDays(new Date(), 7));
    setWeek(newWeek);
  };

  const currentWeek = () => {
    window.location.reload();
  };

  const isSubmitDisabled = employees.some(emp => 
    emp.days.some(day => !day.date_request)
  );

  const isNextDayDisabled = (dayId) => {
    const dayIndex = employees[0].days.findIndex(day => day.id === dayId);
    if (dayIndex === 0) return false;
    const previousDay = employees[0].days[dayIndex - 1];
    return !previousDay.date_request;
  };

  return (
    <div className="mx-auto max-w-[1250px] px-4 sm:px-6 lg:px-8">
      <form
        onSubmit={(event) => handleSubmit(event, {
          bodyData,
          api,
          errorLabel: 'attendance',
        })}
        className="w-full rounded-lg">
        <br />
        <br />
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">Attendance</h1>
            <p className="mt-2 text-sm text-gray-700">
              The next pay period: <DateFormat date={payPeriod.nextPayPeriod} />
            </p>

            <button 
              type="button"
              className="mt-2 px-4 py-1 mb-3 mr-3 bg-blue-500 text-white rounded hover:bg-blue-700"
              onClick={setWeekTo7DaysFromToday}
            >
              Last Payroll
            </button>

            <button 
              type="button"
              className="mt-2 px-4 py-1 mb-3 bg-orange-500 text-white rounded hover:bg-orange-700"
              onClick={currentWeek}
            >
              Current Week
            </button>

            <br />
            <div className="mx-auto max-w-[1250px] px-4 sm:px-6 lg:px-8">
              {payPeriod && payPeriod.week1 ? (
                <ul className="flex space-x-2">
                  {payPeriod.week1.map((dateWeek1) => (
                    <li key={dateWeek1}>
                      {dateWeek1 <= getTodaysDateUtil() &&
                        !UtilDateToDayConvert(dateWeek1).startsWith('Sun') &&
                        !UtilDateToDayConvert(dateWeek1).startsWith('Sat') ? (
                        <button className={`px-4 py-1 text-white rounded-md hover:bg-blue-600 
                                           ${dateToUnixTimestamp(dateWeek1) === week ? 'bg-green-500' : 'bg-blue-500'}`}
                          onClick={(e) => handleWeek(e, dateWeek1)} >
                          {UtilDateToDayConvert(dateWeek1)}
                        </button>
                      ) : ''}
                    </li>
                  ))}
                </ul>
              ) : (
                <LoadingSpinner children={'Loading...'}/>
              )}
            </div>
            {/* <VacationRequestViewCp employee={employees.vacation_request ? employees : []} /> */}
            <br />
            <div className="mx-auto max-w-[1250px] px-4 sm:px-6 lg:px-8">
            {payPeriod && payPeriod.week2 ? (
              <ul className="flex space-x-2">
                {payPeriod.week2.map((dateWeek2) => (
                  <li key={dateWeek2}>
                    {dateWeek2 < UtilgetDateTomorrow() &&
                      !UtilDateToDayConvert(dateWeek2).startsWith('Sun') &&
                      !UtilDateToDayConvert(dateWeek2).startsWith('Sat') ? (
                      <button className={`px-4 py-1 text-white rounded-md hover:bg-blue-600 
                                          ${dateToUnixTimestamp(dateWeek2) === week ? 'bg-green-500' : 'bg-blue-500'}`}
                        onClick={(e) => handleWeek(e, dateWeek2)} >
                        {UtilDateToDayConvert(dateWeek2)}
                      </button>
                    ) : ''}
                  </li>
                ))}
              </ul>
            ) : (
              <LoadingSpinner children={'Loading...'}/>
            )}
            </div>

          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <SubmitButton >Submit</SubmitButton>
          </div>
        </div>
        {isHoliday ? (<p className='mt-5'>Happy Holiday!! No Schedule</p>) :  (
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                        Name
                      </th>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                        Vacation Request
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Attendance
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Status
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Notes
                      </th>
                    </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-200 bg-white">
                    {employees.map((person, index) => (
                      <tr key={person.id} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} divide-x`}>
                        <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                          <div className="flex items-center ml-4">
                            <div className="h-11 w-11 flex-shrink-0">
                              <AvatarLocation className="h-11 w-11 rounded-full" avatar={person.avatar} />
                            </div>
                            <div className="ml-4">
                              <span className='absolute text-gray-400 text-sm relative top-0 pr-3'>
                                ID: {person.id}-{ylasttwo(person.date_hire)}
                              </span>
                              <BadgeFieldOffice location={person.o_f_type} />
                              <div className="font-medium text-blue-700">
                                <NavLink to={`/profile/${person.id}`}>
                                  {person.first_name} {person.last_name}
                                </NavLink>
                              </div>
                              <div className="mt-1 text-gray-900">{person.title}</div>
                              <div className="mt-1 text-gray-500"><DateFormat date={person.date_hire} /></div>
                              <div className="mt-1 text-gray-500"><PhoneNumber number={person.phone} /></div>
                              <div className="mt-1 text-gray-500">Longevity: {person.longevity > 1 ? person.longevity + ' Years' : person.longevity + ' Year'}</div>
                              {person.check_in_out.check_status ? (
                                <div className="mt-1 text-green-600 bg-slate-100 p-2 w-[350px] break-words text-wrap">
                                  checked {person.check_in_out.job_location}
                                  <br />
                                  <span>{formatTimestamp(person.check_in_out.time)}</span>
                                </div>
                              ) : ('')}
                            </div>
                          </div>
                        </td>

                        <td className="flex items-center">
                          {
                          person.vacation_request && person.id !== userData?.users?.id ? 
                          (
                            <div className="mt-1 text-orange-700">
                              PTO Request: <br />{person.vacation_request.type}
                              <br />
                              F: {UtilConvertDateTodayDate(person.vacation_request.date_out)}<br />
                              {person.vacation_request.type === 'Half Vacation Day' ? ('') :
                                (<>T: {UtilConvertDateTodayDate(person.vacation_request.date_end)}</>)
                              }
                              <br />
                              <span
                                onClick={(e) => handleApprovedVacation(person.vacation_request.employee_id, person.vacation_request.id)}
                                className='text-green-500 mr-4 ring-1 p-1 rounded hover:bg-green-500 hover:text-white hover:cursor-pointer'>
                                Approve
                              </span>
                              <span
                                onClick={(e) => handleDenyVacation(person.vacation_request.employee_id, person.vacation_request.id)}
                                className='text-red-500 mr-4 ring-1 p-1 rounded hover:bg-red-500 hover:text-white hover:cursor-pointer'>
                                Deny
                              </span>
                            </div>
                          ) 
                          :
                          ('')
                          }
                        </td>

                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                          <div className="flex flex-col gap-y-2">
                            {person.days.map((day) => (
                              <div key={day.id} className="flex flex-col gap-y-2">
                                {/* Check if the person is on a vacation or sick day */}
                                {day.date_request === 'Vacation Day' || person.vacation_days.some(date => date === UtilConvertTimeStampToDate(week)) ?
                                  (
                                    <>
                                      <span className='text-blue-700 hover:cursor-pointer' onClick={(e) => handleVacationPicked(e, person.id, day.id)}>'On Vacation!!'</span>
                                    </>
                                  )
                                  :
                                  (
                                    <>
                                      <div className="flex items-center">
                                        <input
                                          id={`in-${person.id}-${day.id}`}
                                          name={`date_request-${person.id}-${day.id}`}
                                          value="in"
                                          checked={day.date_request === 'in'}
                                          onChange={(e) => handleChange(e, person.id, day.id)}
                                          type="radio"
                                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        />
                                        <label htmlFor={`in-${person.id}-${day.id}`} className="ml-2 font-medium text-gray-900">
                                          In
                                        </label>
                                      </div>

                                      {/* Sick Day Radio Button */}
                                      {
                                        (() => {
                                          if (person.sick_days.sick_day_taken >= 6 && day.date_request !== 'Sick Day' ) { // Check if maximum sick days are taken
                                            return ''
                                          } else {
                                            return (
                                              <div className="flex items-center">
                                                <input
                                                  id={`SickDay-${person.id}-${day.id}`}
                                                  name={`date_request-${person.id}-${day.id}`}
                                                  value="Sick Day"
                                                  checked={day.date_request === 'Sick Day'}
                                                  onChange={(e) => handleChange(e, person.id, day.id)}
                                                  type="radio"
                                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                />
                                                <label htmlFor={`SickDay-${person.id}-${day.id}`} className="ml-2 font-medium text-gray-900">
                                                  Sick Day
                                                </label>
                                              </div>
                                            );
                                          }
                                        })()
                                      }

                                      {/* Vacation Day Radio Button */}
                                      {
                                        (() => {
                                          if (person.vacations_taken >= 10 && person.longevity < 5 && day.date_request !== 'Vacation Day') {
                                            return '';
                                          } else if (person.vacations_taken >= 15 && person.longevity > 5 && day.date_request !== 'Vacation Day') {
                                            return '';
                                          } else if (person.vacation_allowed === 0 && person.longevity === 0 && day.date_request !== 'Vacation Day') {
                                            return '';
                                          } else {
                                            return (
                                              <div className="flex items-center">
                                                <input
                                                  id={`VacationDay-${person.id}-${day.id}`}
                                                  name={`date_request-${person.id}-${day.id}`}
                                                  value="Vacation Day"
                                                  checked={day.date_request === 'Vacation Day'}
                                                  onChange={(e) => handleChange(e, person.id, day.id)}
                                                  type="radio"
                                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                />
                                                <label htmlFor={`VacationDay-${person.id}-${day.id}`} className="ml-2 font-medium text-gray-900">
                                                  Vacation Day
                                                </label>
                                              </div>
                                            );
                                          }
                                        })()
                                      }

                                      {/* Other Options */}
                                      <div className="flex items-center">
                                        <input
                                          id={`ExcusedPay-${person.id}-${day.id}`}
                                          name={`date_request-${person.id}-${day.id}`}
                                          value="Excused Pay"
                                          checked={day.date_request === 'Excused Pay'}
                                          onChange={(e) => handleChange(e, person.id, day.id)}
                                          type="radio"
                                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        />
                                        <label htmlFor={`ExcusedPay-${person.id}-${day.id}`} className="ml-2 font-medium text-gray-900">
                                          Excused Pay
                                        </label>
                                      </div>
                                      <div className="flex items-center">
                                        <input
                                          id={`NoPay-${person.id}-${day.id}`}
                                          name={`date_request-${person.id}-${day.id}`}
                                          value="No Pay"
                                          checked={day.date_request === 'No Pay'}
                                          onChange={(e) => handleChange(e, person.id, day.id)}
                                          type="radio"
                                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        />
                                        <label htmlFor={`NoPay-${person.id}-${day.id}`} className="ml-2 font-medium text-gray-900">
                                          No Pay
                                        </label>
                                      </div>
                                      <div className="flex items-center">
                                        <input
                                          id={`PartialHour-${person.id}-${day.id}`}
                                          name={`date_request-${person.id}-${day.id}`}
                                          value="Partial Hour"
                                          checked={day.date_request === 'Partial Hour'}
                                          onChange={(e) => handleChange(e, person.id, day.id)}
                                          type="radio"
                                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        />
                                        <label htmlFor={`PartialHour-${person.id}-${day.id}`} className="ml-2 font-medium text-gray-900">
                                          Partial Hour
                                        </label>
                                        {
                                          day.date_request === 'Partial Hour' ? (
                                            <div className="sm:col-span-1">
                                              <div className="ml-2">
                                                <input
                                                  type="text"
                                                  name={`partial_hours-${person.id}-${day.id}`}
                                                  id={`partial_hours_field-${person.id}-${day.id}`}
                                                  maxLength="5"
                                                  value={day.partial_hours || ''}
                                                  onChange={(e) => handleChange(e, person.id, day.id)}
                                                  className="block w-full rounded-md border-0 py-1.5 
                                                        text-gray-900 shadow-sm ring-1 ring-inset 
                                                        ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
                                                        focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                              </div>
                                            </div>
                                          ) : ('')
                                        }
                                      </div>

                                      <div className="flex items-center">
                                        <input
                                          id={`HalfVacationDay-${person.id}-${day.id}`}
                                          name={`date_request-${person.id}-${day.id}`}
                                          value="Half Vacation Day"
                                          checked={day.date_request === 'Half Vacation Day'}
                                          onChange={(e) => handleChange(e, person.id, day.id)}
                                          type="radio"
                                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        />
                                        <label htmlFor={`HalfVacationDay-${person.id}-${day.id}`} className="ml-2 font-medium text-gray-900">
                                          <sup>1</sup>&frasl;<sub>2</sub> Vacation Day
                                        </label>

                                      </div>

                                      {/* <div className="flex items-center">
                                        <input
                                          id={`HalfSickDay-${person.id}-${day.id}`}
                                          name={`date_request-${person.id}-${day.id}`}
                                          value="Half Sick Day"
                                          checked={day.date_request === 'Half Sick Day'}
                                          onChange={(e) => handleChange(e, person.id, day.id)}
                                          type="radio"
                                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        />
                                        <label htmlFor={`HalfSickDay-${person.id}-${day.id}`} className="ml-2 font-medium text-gray-900">
                                          <sup>1</sup>&frasl;<sub>2</sub> Sick Day
                                        </label>

                                      </div> */}

                                    </>
                                  )}
                              </div>
                            ))}
                          </div>
                        </td>


                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                          <div className="flex flex-col gap-y-2">
                            <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${person.vacation_allowed === 0 ? 'bg-red-50 text-red-700 ring-red-600/20' : 'bg-green-50 text-green-700 ring-green-600/20'}`}>
                              PTO Earned: {Math.floor(person.vacation_allowed)}
                            </span>
                            <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${person.vacations_taken > person.vacation_allowed ? 'bg-red-50 text-red-700 ring-red-600/20' : 'bg-green-50 text-green-700 ring-green-600/20'}`}>
                              PTO Used: {person.vacations_taken ? person.vacations_taken.toFixed(1) : '0.0'}
                            </span>
                            <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${person.vacation_remaining <= 0 ? 'bg-red-50 text-red-700 ring-red-600/20' : 'bg-green-50 text-green-700 ring-green-600/20'}`}>
                              PTO Available: {person.vacation_remaining ? person.vacation_remaining.toFixed(1) : '0.0'}
                            </span>
                            <hr />
                            <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${person.sick_days.sick_day_allowed < 1 ? 'bg-red-50 text-red-700 ring-red-600/20' : 'bg-green-50 text-green-700 ring-green-600/20'}`}>
                              Sick Days / Flex: {person.sick_days.sick_day_allowed}
                            </span>
                            <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset 
                                            ${person.sick_days.results > 6 ? 'bg-red-50 text-red-700 ring-red-600/20' : 
                                            'bg-green-50 text-green-700 ring-green-600/20'}
                                            `}>
                              Sick Days / Flex Used: {person.sick_days.sick_day_taken}
                            </span>
                            <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset 
                                            ${person.sick_days.results === 0 ? 'bg-red-50 text-red-700 ring-red-600/20' : 
                                            'bg-green-50 text-green-700 ring-green-600/20'}
                                            `}>
                              Sick Days / Flex Available: {person.sick_days.results}
                            </span>
                          </div>
                        </td>

                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                          <div className="sm:col-span-6">
                            {person.days.map((note) => (
                              <div key={`${person.id}-${note.id}`}>
                                <label htmlFor={`notes-${person.id}-${note.id}`} className="block text-sm font-medium leading-6 text-gray-900">
                                  Notes
                                </label>
                                <div className="mt-2">
                                  <textarea
                                    rows={4}
                                    id={`notes-${person.id}-${note.id}`}
                                    name={`notes-${person.id}-${note.id}`}
                                    value={note.notes || ''}
                                    onChange={(e) => handleChange(e, person.id, note.id)}
                                    className="text-slate-500 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </td>
                        
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </form>
      <br />
      <br />
    </div>
  );
};

export default AttendancePage;