import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import holidaysImage from '../assets/images/holidays.gif';
import independenceDayImage from '../assets/images/independence_day.gif';
import useDateRequestColors from '../hooks/useDateRequestColors';

import '../custom.css';
import ProfileDaysListViewCp from './ProfileDaysListViewCp';
import LegendCP from './LegendCP';

const CalendarViewCp = ({employee}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const dateRequestColors = useDateRequestColors();
  const [days, setDays] = useState([]);
  const [list, setList] = useState([]);
  const [payPeriodDates, setPayPeriodDates] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const [loading, setLoading] = useState(true);
  const [access, setAccess] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const navigate = useNavigate();
  
  const fetchDates = async (employee, year, month) => {
    try {
      const response = await axios.get(`${apiUrl}/time_attendance_crud.php?profile=${employee?.id}&&year=${year}&&month=${month}`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      });
        setAccess(response.data.access);
        const datesList = Array.isArray(response.data.events) ? response.data.events : [];
        const list = Array.isArray(response.data.list) ? response.data.list : [];
        const payperiod = Array.isArray(response.data.pay_period) ? response.data.pay_period : [];
        if (datesList.length === 0) {
          toast.error("No days found!", {
            position: "top-center"
          });
        }
        setDays(datesList);
        setList(list);
        setPayPeriodDates(response.data.pay_period);
    } catch (error) {
      // Access(true);
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center"
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area speak to the CFO.", {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth() + 1;
    fetchDates(employee, year, month);
  }, [currentMonth, employee, access]);

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
  };

  const handlePreviousMonth = () => {
    const newMonth = new Date(currentMonth.setMonth(currentMonth.getMonth() - 1));
    setCurrentMonth(newMonth);
  };

  const handleNextMonth = () => {
    const newMonth = new Date(currentMonth.setMonth(currentMonth.getMonth() + 1));
    setCurrentMonth(newMonth);
  };

  const getColorForEmployee = (event) => {
    const colors = {
      'Vacation Day': 'bg-blue-600',
      'in':'bg-green-600',
      'No Pay': 'bg-red-600',
      'Excused Pay': 'bg-yellow-600', 
      'Partial Hour': 'bg-purple-600',
      'Sick Day': 'bg-pink-600',
      'Half Vacation Day': 'bg-indigo-600'
    };
    return colors[event];
  };

  const generateCalendarGrid = () => {
    const startOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
    const endOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
    const startDate = startOfMonth.getDay() === 0 ? startOfMonth : new Date(startOfMonth.setDate(startOfMonth.getDate() - startOfMonth.getDay()));
    const endDate = endOfMonth.getDay() === 6 ? endOfMonth : new Date(endOfMonth.setDate(endOfMonth.getDate() + (6 - endOfMonth.getDay())));

    const dates = [];
    for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
      const dateString = d.toISOString().split('T')[0];
      const dayObj = days.find(day => day.date === dateString) || { date: dateString, events: [] };
      dates.push(dayObj);
    }
    return dates;
  };

  const calendarDays = generateCalendarGrid();
  const vacation_cal = { name: 'Vacations Cal', href:'#', current:true };
  const overtime = { name: 'YTD', href: '/vacation_ytd', current: false };

  return (
    <>
      <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
        <br/>
        <LegendCP color="fill-red-500" label="No Pay" />
        <LegendCP color="fill-yellow-500" label="Excuse Pay" />
        <LegendCP color="fill-green-500" label="In" />
        <LegendCP color="fill-blue-500" label="Vacation Day" />
        <LegendCP color="fill-purple-600" label="Partial Hour" />
        <LegendCP color="fill-indigo-600" label="Half Vacation Day" />
        <br/>

        <header className="flex items-center justify-between border-b border-gray-200 px-6 py-4 lg:flex-none">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            <time dateTime={currentMonth.toISOString().split('T')[0]}>
              Attendance for: {currentMonth.toLocaleString('default', { month: 'long' })} {currentMonth.getFullYear()}
            </time>
          </h1>
          <div className="flex items-center">
            <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
              <button
                type="button"
                onClick={handlePreviousMonth}
                className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
              >
                <span className="sr-only">Previous month</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>

              <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
              <button
                type="button"
                onClick={handleNextMonth}
                className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
              >
                <span className="sr-only">Next month</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </header>

        <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
          <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
            <div className="bg-white py-2">
              S<span className="sr-only sm:not-sr-only">un</span>
            </div>
            <div className="bg-white py-2">
              M<span className="sr-only sm:not-sr-only">on</span>
            </div>
            <div className="bg-white py-2">
              T<span className="sr-only sm:not-sr-only">ue</span>
            </div>
            <div className="bg-white py-2">
              W<span className="sr-only sm:not-sr-only">ed</span>
            </div>
            <div className="bg-white py-2">
              T<span className="sr-only sm:not-sr-only">hu</span>
            </div>
            <div className="bg-white py-2">
              F<span className="sr-only sm:not-sr-only">ri</span>
            </div>
            <div className="bg-white py-2">
              S<span className="sr-only sm:not-sr-only">at</span>
            </div>
          </div>

          <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
            <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-5 lg:gap-px">
              {calendarDays.map((day) => (
                <div
                  key={day.date}
                  className={classNames(
                    new Date(day.date).getMonth() === currentMonth.getMonth()
                      ? (day.date === '2024-07-04' || day.date === '2024-12-25')
                        ? 'bg-cover bg-center'
                        : 'bg-white'
                      : 'bg-gray-50 text-gray-500',
                      (new Date(day.date).getDay() === 6 || new Date(day.date).getDay() === 5) && 'bg-[#ffedd5]',
                    'relative px-4 py-6'
                  )}
                  style={
                    day.date === '2024-07-04' ? { backgroundImage: `url(${independenceDayImage})` }
                      : day.date === '2024-12-25' ? { backgroundImage: `url(${holidaysImage})` }
                      : {}
                  }
                >
                  <time
                    dateTime={day.date}
                    className={
                      day.date === new Date().toISOString().split('T')[0]
                        ? 'flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white'
                        : 'flex h-8 w-8 items-center justify-center rounded-full'
                    }
                  >
                    {day.date.split('-').pop().replace(/^0/, '')}
                  </time>

                  {day.events.length > 0 && (
                    <ol className="mt-2">
                      {day.events.slice(0, 2).map((event) => (
                        <li key={event.id} className="flex items-center">
                          <span className={classNames('h-2 w-2 rounded-full mr-1', dateRequestColors[event.date_request])}></span>
                          <span className="flex items-center font-medium text-gray-800 group-hover:text-indigo-600">
                            {day.date === '2024-07-04' ? '' : event.date_request}
                          </span>
                        </li>
                      ))}
                      {day.events.length > 2 && <li className="text-gray-500">+ {day.events.length - 2} more</li>}
                    </ol>
                  )}
                </div>
              ))}
            </div>

              {/* this is the mobile side */}

            <div className="isolate grid w-full grid-cols-7 grid-rows-5 gap-px lg:hidden">
              {calendarDays.map((day) => (
                <button
                  key={day.date}
                  type="button"
                  className={classNames(
                    new Date(day.date).getMonth() === currentMonth.getMonth() ? 'bg-white' : 'bg-gray-50',
                    (day.isSelected || day.date === new Date().toISOString().split('T')[0]) && 'font-semibold',
                    day.isSelected && 'text-white',
                    !day.isSelected && day.date === new Date().toISOString().split('T')[0] && 'text-indigo-600',
                    !day.isSelected && new Date(day.date).getMonth() === currentMonth.getMonth() && day.date !== new Date().toISOString().split('T')[0] && 'text-gray-900',
                    !day.isSelected && new Date(day.date).getMonth() !== currentMonth.getMonth() && day.date !== new Date().toISOString().split('T')[0] && 'text-gray-500',
                    (new Date(day.date).getDay() === 6 || new Date(day.date).getDay() === 5) && 'bg-[#ffedd5]',
                    'flex h-20 flex-col px-4 py-6 hover:bg-gray-100 focus:z-10'
                  )}
                  onClick={() => setSelectedDay(day)}
                >
                  <time dateTime={day.date}
                    className={classNames(
                      day.isSelected && 'flex h-8 w-8 items-center justify-center rounded-full',
                      day.isSelected && day.date === new Date().toISOString().split('T')[0] && 'bg-indigo-600',
                      day.isSelected && day.date !== new Date().toISOString().split('T')[0] && 'bg-gray-900',
                      'ml-auto'
                    )}
                  >
                    {day.date.split('-').pop().replace(/^0/, '')}
                  </time>
                  <span className="sr-only">{day.events.length} events</span>
                  {day.events.length > 0 && (
                    <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                      {day.events.map((event) => (
                        <span key={event.id} className={classNames('rounded-full mx-0.5 mb-1 h-1.5 w-1.5', dateRequestColors[event.date_request])}></span>
                      ))}
                    </span>
                  )}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      <ProfileDaysListViewCp list={list} payPeriod={payPeriodDates} access={access} employee_id={employee?.id}/>
      <br/>
      <br/>
    </>
  );
};

export default CalendarViewCp;
