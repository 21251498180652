import React, { useState, useEffect, Fragment } from 'react';
// import WipComments from '../components/WipComments';  // Update the import path if necessary

import axios from 'axios';
import { useParams, useNavigate, NavLink } from 'react-router-dom';
import { Dialog, DialogPanel, Label, Listbox, ListboxButton, ListboxOption, ListboxOptions, Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { Bars3Icon, CalendarDaysIcon, CreditCardIcon, EllipsisVerticalIcon, FaceFrownIcon, FaceSmileIcon, FireIcon, HandThumbUpIcon, HeartIcon, PaperClipIcon, UserCircleIcon, XMarkIcon as XMarkIconMini } from '@heroicons/react/20/solid';
import { BellIcon, XMarkIcon as XMarkIconOutline } from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CurrencyFormat from '../components/CurrencyFormat';
import AvatarLocation from '../components/AvatarLocation';
import DateFormat from '../components/DateFormat';
import WipComments from '../components/ModalWipNotesSection';
import CreditHoldBannerCp from '../components/CreditHoldBannerCp';
import FileLocationCp from '../components/FileLocationCp';
import SignedProposalCP from '../components/SignedProposalCP';
import FileLocation from '../components/FileLocation';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const WipDetailPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const { key } = useParams();
  const [wip, setWip] = useState({
    id: '',
    job_number: '',
    job_site: '',
    customer_id: '',
    job_description: '',
    type: '',
    f_p_type: '',
    prev_wage: '',
    price: '',
    invoiced: '',
    paid: '',
    void: '',
    pm: '',
    tax_status: '',
    date: '',
    complete: 0,
    acc_approval: '',
    eng_approval: '',
    file:''
  });
  const [pm, setPm] = useState();
  const [notes, setNotes] = useState([]);
  const [accountVendor, setAccountVendor] = useState([]);
  const [engineering, setEngineering] = useState([]);
  const [invoiceHistory, setInvoiceHistory] = useState([]);
  const [creditHold, setCreditHold] = useState([]);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [deliveryTracking, setDeliveryTracking] = useState([]);
  const [employeeDepartment, setEmployeeDepartment] = useState([]);
  const [bodyDataNotes, setBodyDataNotes] = useState({
    status: 'submit_notes',
    data: {}
  });
  const api = `${apiUrl}/wip_crud.php`;

  const handleNoteChangeParent = (id, newNote) => {
    setNotes((prevNotes) => (Array.isArray(prevNotes) ? [...prevNotes, newNote] : [newNote]));
  };

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const response = await axios.get(`${apiUrl}/wip_crud.php`, {
          params: {
            getWipDetailStat: key
          }
        });
        const data = response.data;

        if (data.error) {
          toast.error(data.error, {
            position: 'top-center'
          });
        }
        setWip(data.wip_detail);
        setPm(data.employees);
        setNotes(data.notes ?? []);
        setInvoiceHistory(data.invoice_history ?? []);
        setCreditHold(data.credit_hold ?? []);
        setAccountVendor(data.account_vendor ?? []);
        setDeliveryTracking(data.delivery_tracking ?? []);
        setEngineering(data.engineering_data ?? []);
        setEmployeeDepartment(data.department ?? []);
      } catch (error) {
        toast.error("Oops minor issue with the data.", {
        position: "top-center"
        });
        if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
        position: "top-center"
        });
        navigate('/login');
        } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
        position: "top-center"
        });
        }
      }
    };

    fetchCustomer();
  }, [key, apiUrl, navigate]);

  return (
    <>
    {creditHold ? (
      <>
       <CreditHoldBannerCp children={(
          <>
          Amount owed: <CurrencyFormat value={creditHold.amount}/>
          | Comment : {creditHold.notes || ''}
          </>
       )} href={'/credit_hold'}/>
      </>
    ) : ('')}

    {wip?.customer_hold  ? (
      <>
       <CreditHoldBannerCp children={(
          <>
          Company on hold 
          </>
       )} href={'/customers'}/>
      </>
    ) : ('')}
      <main>
        <div className="mx-auto max-w-[1250px] px-4 py-16 sm:px-6 lg:px-8">
          <a href="/wip_list/aj" className="text-sm font-semibold leading-6 text-blue-700">
            <span aria-hidden="true">&larr;</span> Back to Wip
          </a>
            {employeeDepartment === 'Sales' || employeeDepartment === 'Admin' || employeeDepartment === 'Install' ? (
              <NavLink to={`/wip/edit/${wip.id}`}>
                <span className="text-sm font-semibold leading-6 text-red-600 px-3">
                | Edit
                </span>
              </NavLink>
            ) : ('')}
          <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <div className="lg:col-start-3 lg:row-end-1">
              {/* <h2 className="sr-only">Summary</h2> */}
              <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-300">
                <dl className="flex flex-wrap">
                  <div className="flex-auto pl-6 pt-6">
                    <dt className="text-sm font-semibold leading-6 text-gray-900">Price</dt>
                    <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">
                      {<CurrencyFormat value={wip?.price} />}
                    </dd>
                  </div>
                  <div className="flex-none self-end px-6 pt-4">
                    <dt className="sr-only">Status</dt>
                    <dd className="rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-600/20">
                      {
                        wip?.tax_status === "tax" ?  'Taxable' : '' ||
                        wip?.tax_status === "CI"  ?  'Capitol Improvement' : '' ||
                        wip?.tax_status === "CE"  ?  'Contractor Exempt' : ''||
                        wip?.tax_status === "EO"  ?  'Exempt Organization' : '' ||
                        wip?.tax_status === "OE"  ?  'Other Exempt' : ''
                      }
                    </dd>
                  </div>
                  <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
                    <dt className="flex-none">
                      <span className="sr-only">Project Manager</span>
                      {<AvatarLocation className="h-8 w-8 rounded-full" avatar={pm?.avatar} />}
                    </dt>
                    <dd className="text-sm font-medium leading-6 text-gray-900">
                      {pm?.first_name} {pm?.last_name} (Project Manager)
                    </dd>
                  </div>
                  <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                    <dt className="flex-none">
                      <span className="sr-only">Added</span>
                      <CalendarDaysIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
                    </dt>
                    <dd className="text-sm leading-6 text-gray-500">
                      <time dateTime="2023-01-31"><DateFormat date={wip?.date} /></time>
                    </dd>
                  </div>
                </dl>
                <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
                  <span className="text-sm font-semibold leading-6 text-gray-900">
                    View Proposal <span aria-hidden="true">&rarr;</span>
                  </span>
                  {/* <SignedProposalCP expFile={`${wip.file}`} /> */}
                  <FileLocation expFile={wip.file} where={'signed_proposal'}/>
                </div>
              </div>
            </div>
            <div className="-mx-4 px-4 py-8 shadow-sm ring-2 ring-gray-400/10 sm:mx-0 sm:rounded-lg sm:px-8 sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-16 xl:pb-20 xl:pt-16">
              <h2 className="text-base font-semibold leading-6 text-gray-900">
                Proposal # {wip?.job_number}
              </h2>
              <dl className="mt-6 grid grid-cols-1 text-sm leading-6 sm:grid-cols-2">
                <div className="sm:pr-4"></div>
                <div className="mt-2 sm:mt-0 sm:pl-4"></div>
                <div className="mt-6 border-t border-gray-900/5 pt-6 sm:pr-4">
                  <dt className="font-semibold text-gray-900">
                    {wip?.job_site}
                  </dt>
                  <dd className="mt-2 text-gray-500">
                    <span className="font-medium text-gray-900">
                      {wip?.customer_name}
                    </span>
                    <br />
                    {wip?.job_description}
                    <br />
                    {wip?.type}
                  </dd>
                </div>
                <div className="mt-8 sm:mt-6 sm:border-t sm:border-gray-900/5 sm:pl-4 sm:pt-6">
                  <dd className="mt-2 text-gray-500">
                    <span className="font-medium text-gray-900">
                      {wip?.f_p_type === 'F' ? 'Full Install' : 'Part & Programming'}
                    </span>
                    <br />
                      Paid: {wip?.paid}%
                    <br />
                      Invoiced: {wip?.invoiced}%
                    <br />
                      {wip?.acc_approval === 1 ? 'Vendor payment required' : 'No vendor required'}
                      <span className={`${wip?.acc_approval === 1 && wip?.acc_status === "complete" ? 'text-green-500' : 'text-red-500'}`}>
                        {wip?.acc_approval === 1 && wip?.acc_status === "complete" ? ': Paid 100%' : ''}
                      </span>
                    <br />
                      <span className={`${wip?.acc_approval === 1 && wip?.acc_status === "complete" ? '' : ''}`}>
                        {wip?.eng_approval === 1 ? 'Engineering Pending' : 'No engineering required'}
                      </span>
                      <span className={`${wip?.eng_approval === 1 && wip?.eng_status === 'complete' ? 'text-green-500' : 'text-red-500'}`}>
                        {wip?.eng_approval === 1 && wip?.eng_status === 'complete' ? ': Done' : ''}
                      </span>
                  </dd>
                </div>
              </dl>

                {accountVendor.id ? (
                  <>
                  <div className="flex w-full items-center justify-between mt-10 border-t border-b pt-2 bg-[#fffbeb] p-4">
                  <div className="flex-1 truncate">
                    <div className="flex items-center space-x-3">
                      <h3 className="truncate text-sm font-medium text-gray-900">
                        <NavLink to={'/accounting'}>Vendor: {accountVendor?.vendor_name}</NavLink>
                      </h3>
                      <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                        i: {accountVendor?.adv_inv_num}
                      </span>
                    </div>
                    <p className="mt-1 truncate text-sm text-gray-500">
                      {/* <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                       <circle r={1} cx={1} cy={1} />
                      </svg> */}
                     {accountVendor.vendor_rate_paid && accountVendor.vendor_rate_paid}% Paid
                    </p>
                    <p>{accountVendor?.notes}</p>
                  </div>
                  {/* <img alt="" src={accountVendor?.imageUrl} className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300" /> */}
                  </div>
                  </>
                ) : ('')}

                {engineering.id ? (
                  <>
                  <div className="flex w-full items-center justify-between mt-10 border-t border-b pt-2 bg-[#e8f1ff] p-4">
                  <div className="flex-1 truncate">
                    <div className="flex items-center space-x-3">
                      <h3 className="truncate text-sm font-medium text-gray-900">
                        Engineering Paper Work:
                      </h3>
                      <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                         {engineering?.approved_paperwork === 1 ? 'Approved' : 'Pending Status'}
                      </span>
                    </div>
                    <p className="mt-1 truncate text-sm text-gray-400 text-wrap">
                      {engineering?.paperwork ? 'Work in progress: '+engineering?.paperwork+' | ' : '' } 
                      {engineering?.engineering_notes ? engineering?.engineering_notes : ('') } 
                    </p>
                  </div>
                  </div>
                  </>
                ) : ('')}
                

              {invoiceHistory.length > 0 ? (
                <table className="mt-16 w-full whitespace-nowrap text-left text-sm leading-6">
                  <colgroup>
                    <col className="w-full" />
                    <col />
                    <col />
                    <col />
                  </colgroup>
                  <thead className="border-b border-gray-200 text-gray-900">
                    <tr>
                      <th scope="col" className="px-0 py-3 font-semibold">
                        Invoiced history
                      </th>
                      <th scope="col" className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell">
                        Invoiced Rate
                      </th>
                      <th scope="col" className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell">
                        Payment Rate
                      </th>
                      <th scope="col" className="py-3 pl-8 pr-0 text-right font-semibold">
                        WIP
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceHistory?.map((item) => (
                      <tr key={item.id} className="border-b border-gray-200">
                        <td className="max-w-0 px-0 py-5 align-top">
                          <div className="truncate font-medium text-gray-900"><DateFormat date={item.date} /></div>
                          <div className="truncate text-gray-500">by {item.by_user}</div>
                        </td>
                        <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                          {item.invoice_rate}%
                        </td>
                        <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                          {item.paid_rate}%
                        </td>
                        <td className="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">
                          <CurrencyFormat value={wip?.price * item?.invoice_rate / 100} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className='p-4'>No invoiced history</div>
              )}

              {deliveryTracking.length > 0 ? 
              (
                <table className="mt-16 w-full text-left text-sm leading-6">
                  <colgroup>
                    <col className="w-full" />
                    <col />
                    <col />
                    <col />
                  </colgroup>
                  <thead className="border-b border-gray-200 text-gray-900">
                    <tr>
                      <th scope="col" className="px-0 py-3 font-semibold">
                       Delivery Receipt Notes
                      </th>
                      <th scope="col" className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell">
                        File
                      </th>
                      <th scope="col" className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell">
                        status
                      </th>
                      <th scope="col" className="py-3 pl-8 pr-0 text-right font-semibold">
                        By | Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {deliveryTracking?.map((item) => (
                      <tr key={item.id} className="border-b border-gray-200">
                        <td className="max-w-0 px-0 py-5 align-top">
                          {item.notes}
                        </td>
                        <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
                          <FileLocation expFile={item.file} where={'delivery_receipt'}/>
                        </td>
                        <td className="py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">
                          {item.status}
                        </td>
                        <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell flex-wrap">
                          <div className="truncate font-medium text-gray-900">
                            <DateFormat date={item.date} />
                          </div>
                          <div className="truncate text-gray-500">
                            by {item.employee_name}
                          </div>
                          <div className="truncate text-gray-500">
                            id: {item.id}
                          </div>
                        </td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              ) 
              : 
              (
                <div className='p-4'>No Delivery Receipt</div>
              )}

            </div>

            <div className="lg:col-start-3">
              <h2 className="text-sm font-semibold leading-6 text-gray-900">Activity</h2>
              <WipComments
                api={api}
                inside={'notes'}
                notes={notes}
                bodyData={bodyDataNotes}
                val={''}
                id={wip?.id}
                handleNoteChangeParent={handleNoteChangeParent}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default WipDetailPage;
