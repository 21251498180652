import { Fragment, React } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import logo from '../assets/images/logo.png';
import { NavLink, useNavigate } from 'react-router-dom';
import AvatarLocation from '../components/AvatarLocation';
import { useUserData } from '../context/UserData';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';


const currentUrl = window.location.href;
const url = currentUrl.split('/')[3];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const TechNavBar = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const userData = useUserData();
  const navigate = useNavigate();
  // console.log(userData, 'this is coming from user');

  const handleSignOut = async () => {

    const bodyData = {
      status: 'signout',
      data: { }
    };

    try {
      const response = await axios.post(`${apiUrl}/users_login.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      
      if (response.data.success) {
          navigate('/login');
      } 
    } catch (error) {
      console.error("Authentication error:", error);
      
    }
  };



  const links = [
    { to: "#", label: "News Feed" },
    { to: "#", label: "Request Access Code" },
    { to: "#", label: "Maintenance Inspections" },
    { to: "#", label: "FDNY Inspections" },
    { to: "twip", label: "WIP" },
    { to: "twkt", label: "Timesheets" },
    { to: "#", label: "Service Calls" },
    { to: "/t/tcredit_hold", label: "Credit Hold List" },
    { to: "#", label: "Methods of Procedure" },
    { to: "/t/tvacationreq", label: "Vacation Request" },
    { to: "/t/tchkinout", label: "Checking" },
    { to: "#", label: "Overtime Availability" }
  ];

  return (
    <Disclosure as="nav" className="bg-gray-800 sticky top-0 z-50">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-[1280px] px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <img className="h-8 w-auto" src={logo} alt="IRL-Mainframe" />
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:block">
                <div className="flex items-center">
                  <button
                    type="button"
                    className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>
                        <AvatarLocation className="h-10 w-10 rounded-full" avatar={userData?.avatar || 'no_pic.png'} />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <NavLink
                              to="#"
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              Your Profile
                            </NavLink>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <NavLink
                              to="#"
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              Settings
                            </NavLink>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <NavLink
                              onClick={handleSignOut}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              Sign out
                            </NavLink>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
              <div className="-mr-2 flex sm:hidden">
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {links.map((link) => (
                <Disclosure.Button
                  key={link.label}
                  as={NavLink}
                  to={link.to}
                  className={`block rounded-md px-3 py-2 text-base font-medium 
                             hover:bg-gray-700 hover:text-white ${link.to === '#' ? 'text-red-500' : 'text-gray-300'}`}
                  onClick={Disclosure.close}
                >
                  {link.label}
                </Disclosure.Button>
              ))}
            </div>
            <div className="border-t border-gray-700 pb-3 pt-4">
              <div className="flex items-center px-5">
                <div className="flex-shrink-0">
                  <AvatarLocation className="h-10 w-10 rounded-full" avatar={userData?.avatar || 'no_pic.png'} />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-white">
                    {userData?.users?.first_name || ''} {userData?.users?.last_name || ''}
                  </div>
                  <div className="text-sm font-medium text-gray-400">
                    {userData?.users?.email || ''}
                  </div>
                </div>
                <button
                  type="button"
                  className="relative ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-3 space-y-1 px-2">
                <Disclosure.Button
                  as={NavLink}
                  to={`/t/tp/${userData?.users?.id}`}
                  className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                >
                  Profile
                </Disclosure.Button>
              </div>
              <div className="mt-3 space-y-1 px-2">
                <Disclosure.Button
                  as={NavLink}
                  onClick={handleSignOut}
                  className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                >
                  Sign out
                </Disclosure.Button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default TechNavBar;
