import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchBarCP from '../components/SearchBarCP';  // Ensure correct import
import DateFormat from '../components/DateFormat';
import SubMenu from '../components/SubMenu';
import HeadingCp from '../components/HeadingCp';
import LoadingSpinner from '../components/LoadingSpinner';

const EstimatingListPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const [showVoid, setShowVoid] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [list, setList] = useState([]);
  const [estimate, setEstimate] = useState([]);
  const [filterEstimate, setFilterEstimate] = useState([]);
  const navigate = useNavigate();

  const toggleShowVoid = () => {
    setShowVoid(!showVoid);
  };

   useEffect(() => {
    const fetchEstimates = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/estimate_crud.php`, {
          params: {
            listEsSystem: true // Adjust the parameter based on your API requirements
          }
        });
        console.log(response.data)
        setList(response.data || []);
        setEstimate(response.data || []);
        setFilterEstimate(response.data || {});
      } catch (error) {
          toast.error("Oops minor issue with the data.", {
          position: "top-center"
          });

          if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", {
          position: "top-center"
          });
          navigate('/login');
          } else if (error.response && error.response.status === 403) {
          // setAccess(true);
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: "top-center"
          });
          }
      } finally {
        setLoading(false);
      }
    };

    fetchEstimates();
  }, [apiUrl]);


  const handleSearchClick = async () => {
    setLoading(true);
    const bodyData = {
      status: 'search',
      data: { search: searchTerm }
    };

    try {
      const response = await axios.post(`${apiUrl}/wip_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.data && Array.isArray(response.data)) {
        setEstimate(response.data);
        setFilterEstimate(response.data);
      } else {
        handleSearchChange();
        setEstimate([]);
        setFilterEstimate([]);
        toast.error('Not found ' + searchTerm, {
          position: 'top-center'
        });
      }
    } catch (error) {
      console.error('Error searching active projects', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value === '') {
      setFilterEstimate(estimate);
    } else {
      const filtered = estimate.filter(estimate =>
        (estimate.customer_name && estimate.customer_name.toLowerCase().includes(value.toLowerCase())) ||
        (estimate.job_number && estimate.job_number.toLowerCase().includes(value.toLowerCase())) ||
        (estimate.job_site && estimate.job_site.toLowerCase().includes(value.toLowerCase()))
      );
      setFilterEstimate(filtered);
    }
  };

  const addRevision = (revision, id, index) => {
    // Define the function logic
    console.log('Add revision:', revision, id, index);
  };

  const editEstimate = (id) => {
    // Define the function logic
    console.log('Edit estimate:', id);
  };

  const editFCS = (id) => {
    // Define the function logic
    console.log('Edit FCS:', id);
  };

  const editDGP = (id) => {
    // Define the function logic
    console.log('Edit DGP:', id);
  };

  const editFD = (id) => {
    // Define the function logic
    console.log('Edit FD:', id);
  };

  const viewProposal = (id) => {
    // Define the function logic
    console.log('View proposal:', id);
  };

  const viewDetail = (id) => {
    // Define the function logic
    console.log('View detail:', id);
  };

  const generateJobNumber = (id, revision, type) => {
    // Define the function logic
    console.log('Generate job number:', id, revision, type);
  };

  const SubmitEstimate = (id, index) => {
    // Define the function logic
    console.log('Submit estimate:', id, index);
  };

  const postDiscount = (id, userId, index) => {
    // Define the function logic
    console.log('Post discount:', id, userId, index);
  };

    const getLinksForJob = (estimate) => {
    let links = [
      { name: 'Add Revision', click:() => navigate(`#`) },
      { name: 'Rivision', click:() => navigate(`#`) },
      { name: 'Edit Estimate', click:() => navigate(`/estimate_pro/${estimate.id}`) },
      { name: 'Equipments', click:() => navigate(`/estimate_equipment/${estimate.id}`) },
      // { name: 'Edit FCS', click: '' },
      // { name: 'DGP', click: '' },
      // { name: 'Field Devices', click: '' },
      { name: 'Detail', click:() => navigate(`/estimate_detail/${estimate.id}`) },
      { name: 'View Proposal', click: () => navigate(`/estimate_proposal/${estimate.id}`) },
      { name: 'Generate estimate-Number', click:() => navigate(`#`) },
      { name: 'Generate Estimate Job-Number(ND)', click:() => navigate(`#`) },
      { name: 'Void', click:() => navigate(`#`) },
      // { name: estiamte.void === 1 ? 'Remove void' : 'Void Job', click: (e, tvoid, index) => voidJobSubmit(estiamte.id, estiamte.void, index) },
      // { name: estiamte.credit_hold.job_number === estiamte.job_number ? 'Remove Hold' : 'Add Credit Hold',
      //         click: () => {
      //                        if(estiamte.credit_hold.job_number === estiamte.job_number)
      //                        {
      //                         handleCreditHoldDelete(estiamte.credit_hold.id, estiamte.id)
      //                        }
      //                        else
      //                        {
      //                         setJobNumber(estiamte.job_number);
      //                         setIsModalOpen(true); 
      //                       }
      //                      }
      // },
    ];

    return links;
  };


return (
    <>
      <div className="mx-auto max-w-[1250px] px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="sm:flex-auto mt-8 mb-5">
            <HeadingCp label={'Estimate Project List'} />
          </div>
        </div>
        <div className="-mx-4 mt-8">
          <div className="mt-5 sm:mt-0 sm flex space-x-3 items-center mb-10">
            <NavLink to="/estimate_pro/new">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-400 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
              >
                New Estimate +
              </button>
            </NavLink>
            {/* <button
              onClick={toggleShowVoid}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-400 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              {showVoid ? 'Show Non-Void' : 'Show Void'}
            </button> */}
            <SearchBarCP search={searchTerm} handleChange={handleSearchChange} onClick={handleSearchClick} />
            {/* <NavLink to={'/credit_hold'}>
              <span
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800 hover:cursor-pointer"
              >
                Reset
              </span>
            </NavLink> */}
          </div>
          <div className="tableContainer">
            {loading ? (
              <LoadingSpinner>Loading....</LoadingSpinner>
            ) : (
              <>
                {filterEstimate.length > 0 ? (
                  <table className="min-w-full divide-y divide-x divide-gray-300 bg-gray-200 border rounded-lg divide-x">
                    <thead>
                      <tr className="divide-x divide-gray-400">
                        <th className=" px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Menu ID</th>
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Date</th>
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Sales / Job#</th>
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Bidding Address</th>
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Attention</th>
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Company</th>
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Rv</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {filterEstimate.map((estimate, index) => (
                        <tr key={estimate.id} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} divide-x`}>
                          
                          <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pr-0">
                            <SubMenu label={estimate.id} idItem={estimate.id} links={getLinksForJob(estimate)} />
                            <dl className="font-normal lg:hidden">
                              <dt className="sr-only">Title</dt>
                              <dd className="mt-1 truncate text-gray-700">{estimate.title}</dd>
                              <dt className="sr-only sm:hidden">Email</dt>
                              <dd className="mt-1 truncate text-gray-500 sm:hidden">{estimate.email}</dd>
                            </dl>
                          </td>

                          <td className="px-3 py-4 text-sm text-gray-500 lg:table-cell">
                            <DateFormat date={estimate.date} />
                          </td>

                          <td className="px-3 py-4 text-sm text-gray-500">
                            {estimate.user_id}
                          </td>

                          <td className="px-3 py-4 text-sm text-gray-500">
                            {estimate.bidding_address}
                          </td>

                          <td className="px-3 py-4 text-sm text-gray-500">
                            {estimate.att}
                          </td>

                          <td className="px-3 py-4 text-sm text-gray-500">
                            <p className="text-gray-800 font-bold">{estimate.contact_name}</p>
                            <p className="text-xs">{estimate.contact_address}</p>
                            <p className="text-xs">{estimate.contact_city} {estimate.contact_state} {estimate.contact_zip}</p>
                            <p className="text-xs">{estimate.contact_phone}</p>
                            <p className="text-xs">{estimate.contact_email}</p>
                          </td>

                          <td className="px-3 py-4 text-sm text-gray-500">
                            {estimate.revision > 0 ? (
                              <span className="inline-flex items-center space-x-2 text-blue-700">
                                <span>Revision</span>
                                <span>{estimate.revision}</span>
                              </span>
                            ) : (
                              ''
                            )}
                          </td>
                          
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  'No data'
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EstimatingListPage;
