import React, { useState } from 'react'
import SideNav from '../components/SideNav';
import DayOff from '../components/DayOff';
import Alerts from '../components/Alerts';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectSalesUser from '../components/SelectSalesUser';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import SubmitButton from '../components/SubmitButton';
// import { Nav } from 'react-bootstrap';

const JobGen = () => {
const [jobType, setJobType] = useState('None');
const [selectedUser, setSelectedUser] = useState(null);
const navigate = useNavigate();


const handleChange = (e) => {
      const { value } = e.target;
      setJobType(value);
};

const handleSubmit = async (event) => {
    event.preventDefault();  // Prevent the default form submit behavior
    const apiUrl = process.env.REACT_APP_API_URL;

    const bodyData = {
        status: 'job_gen',
        data: {sale_code: selectedUser.sale_code, job_type: jobType}
    };

    try {
        const response = await axios.post(`${apiUrl}/proposal_list_crud.php`, bodyData, {
            headers: { 'Content-Type': 'application/json' }
        });

        const data = response.data;  // Axios automatically parses the JSON, so no need to call JSON.parse()
        navigate("/proposal/new/"+data.job_number);
    } catch (error) {
      toast.error("Oops minor issue with the data.", {
      position: "top-center"
      });

      if (error.response && error.response.status === 401) {
      toast.error("Session expired. Please login again.", {
      position: "top-center"
      });
      navigate('/login');
      } else if (error.response && error.response.status === 403) {
      // setAccess(true);
      toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
      position: "top-center"
      });
      }
    }
};



  return (
    <>
    
    <div className="max-w-[1220px] mx-auto grid grid-cols-1 md:grid-cols-[200px_minmax(540px,_1fr)_260px] gap-x-4">

      {/* <!-- Side Nav: 200px, hidden on small screens --> */}
      <div className="hidden md:block">
        <div className='pt-6 pr-3'>
          <SideNav />
        </div>
        <br/>
        <br/>
      </div>
      
      {/* <!-- Main Content: Expand on small screens --> */}
      <div className=" p-4">       
        <div className='bg-white p-10 rounded-lg drop-shadow-md'>
          <h4 className='mt-10 text-lg'>Job Number Generator</h4>
          <p className='text-red-600'>Note: if this new job number is linked to an estimate please use the estimating app to generate the job number</p>

          <form onSubmit={handleSubmit}>
            <div className='max-w-[200px]'>
              <p className='mt-10'>Sales Code</p>
               <SelectSalesUser selected={selectedUser} setSelected={setSelectedUser} />
            </div>

            <div className='max-w-[200px]'>
              <p className='mt-10'>Select job number type</p>

                <div className="relative flex gap-x-3">
                  <div className="flex h-6 items-center">
                    <input
                      id="Fil"
                      name="type"
                      value="FILL"
                      onChange={handleChange}
                      type="radio"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      checked={jobType === 'FILL'}
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label htmlFor="Fil" className="font-medium text-gray-900">
                      Fil
                    </label>
                  </div>
                </div>

                <div className="relative flex gap-x-3">
                  <div className="flex h-6 items-center">
                    <input
                      id="Exp"
                      name="type"
                      value="EXP"
                      onChange={handleChange}
                      type="radio"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      checked={jobType === 'EXP'}
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label htmlFor="Exp" className="font-medium text-gray-900">
                      Exp
                    </label>
                  </div>
                </div>

                <div className="relative flex gap-x-3">
                  <div className="flex h-6 items-center">
                    <input
                      id="None"
                      name="type"
                      value="None"
                      onChange={handleChange}
                      type="radio"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      checked={jobType === 'None'}
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label htmlFor="None" className="font-medium text-gray-900">
                      None
                    </label>
                  </div>
                </div>

              <div className="mt-20 flex items-center justify-start gap-x-6">
                <NavLink to="/">
                <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                  Cancel
                </button>
                </NavLink>
                <SubmitButton disabled={!selectedUser} children={'Submit'} /> 
              </div>
            </div>
          </form>

        </div>
      </div>
      
      {/* <!-- Widgets: 200px, hidden on small screens --> */}
      <div className="hidden md:block ">
        <div className='pt-4'>
          <div className='border-2'>
            <DayOff />
          </div> 
        <br/>
          <Alerts />
        </div>
      </div>

    </div>

    </>
  )
} 

export default JobGen